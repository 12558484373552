<template> 
  <div class="input-container h-[48px] relative leading-tight flex justify-between items-center gap-2 border border-solid rounded p-4 border-silver">
    <input :tabindex="tabindex" :disabled="disabled" v-bind="(min && max) ? { min, max } : {}" @input="handleInput($event)" @focus="handleInputFocus" @blur="handleInputBlur($event)" :ref="inputRef" :value="value" :id="inputId" :type="type" required class="text-young-night z-[1] -m-4 px-4 h-[48px] w-full flex-grow border-0 bg-transparent">
    <label :for="inputId" :class="`absolute flex gap-[1px] bg-${labelBackground || 'layout'} mx-auto cursor-text`">
      {{ labelPlaceholder }}
      <span v-if="required" class="text-fluorescent-red text-lg leading-none">*</span>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['inputId', 'inputRef', 'labelPlaceholder', 'labelBackground', 'type', 'min', 'max', 'value', 'disabled', 'required', 'tabindex'],
  methods: {
    handleInput (event) {
      switch (true) {
      case this.min && event.target.value < this.min :
        event.target.value = this.min
        break
      case this.type === 'number' && event.target.value <= 0 :
        event.target.value = null
        break
      case this.max && event.target.value > this.max :
        event.target.value = this.max
        break
      default:
        event.target.value = event.target.value
      }
      this.$emit('input', event.target.value)
    },
    handleInputFocus () {
      // Emit a custom 'focus' event when the input is focused
      this.$emit('focus')
      // Select the text when focused
      this.$nextTick(() => {
        if (this.$refs[this.inputRef]) {
          this.$refs[this.inputRef].select()
        }
      })
    },
    handleInputBlur (event) {
      // Emit a custom 'blur' event when the input is blured
      this.$emit('blur', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  top: 50%; 
  left: 14px;
  color: #737373;
  transition: all .15s ease-in-out;
  transform: translateY(-50%);
}
input:valid,
input:focus,
input:disabled {
  & + label {
    top: -0.5px;
    left: 10px;
    font-size: 12px;
    padding: 0px 4px;
  }
}
input:disabled {
  & + label {
    color: #BFBFBF;
  }
}
.input-container:has(input:focus) {
  outline: 1px solid #1C5BFE;
  border: 1px solid #1C5BFE !important;
}
.input-container:has(input:disabled) {
  border: 1px solid #BFBFBF !important;
  input, .slot-item {
    color: #BFBFBF !important;
  }
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none; 
  appearance: none; 
  margin: 0;
}
</style>